// const url = "http://localhost:2181/"; //rishabh local
const url = "https://node-hospitality.mobiloitte.io/"; // staging
const user = `${url}api/v1/user`;
const category = `${url}api/v1/category`;
const admin = `${url}api/v1/admin`;
const tag = `${url}api/v1/tag`;
const activity = `${url}api/v1/activity`;
const cuisine = `${url}api/v1/cuisine`;
const drink = `${url}api/v1/drink`;
const dish = `${url}api/v1/dish`;
const food = `${url}api/v1/food`;
const offer = `${url}api/v1/offer`;
const facility = `${url}api/v1/facility`;

const ApiConfig = {
  // userSignup: `${account}/create-account`,
  login: `${user}/login`,
  resendOTP: `${user}/resendOTP`,
  verifyOTP: `${user}/verifyOTP`,
  resetPassword: `${user}/resetPassword`,
  uploadFile: `${user}/uploadFile`,
  createCategory: `${user}/createCategory`,
  getProfile: `${user}/getProfile`,
  updateProfile: `${user}/updateProfile`,
  changePassword: `${user}/changePassword`,
  businessUserList: `${user}/businessUserList`,
  userList: `${admin}/userList`,
  updateUser: `${admin}/updateUser`,
  viewUserDetails: `${admin}/viewUserDetails`,
  businessOwnerOfferListForAdmin: `${admin}/businessOwnerOfferListForAdmin`,
  feedbackList: `${admin}/feedbackList`,
  venueList: `${admin}/venueList`,
  tagList: `${tag}/tagList`,
  adminTagList: `${tag}/adminTagList`,
  createAccount: `${user}/createAccount`,
  createTag: `${tag}/createTag`,
  adminActivityList: `${activity}/adminActivityList`,
  createActivity: `${activity}/createActivity`,
  updateActivity: `${activity}/updateActivity`,
  updateTag: `${tag}/updateTag`,
  tagDetails: `${tag}/tagDetails`,
  adminCuisineList: `${cuisine}/adminCuisineList`,
  updateCuisine: `${cuisine}/updateCuisine`,
  cuisineDetails: `${cuisine}/cuisineDetails`,
  createCuisine: `${cuisine}/createCuisine`,
  adminDrinkList: `${drink}/adminDrinkList`,
  updateDrink: `${drink}/updateDrink`,
  drinkDetails: `${drink}/drinkDetails`,
  createDrink: `${drink}/createDrink`,
  addOffer: `${offer}/addOffer`,
  updateOffer: `${offer}/updateOffer`,
  offerListForBusinessOwner: `${offer}/offerListForBusinessOwner`,
   
  //admin
  viewStaticContent: `${admin}/viewStaticContent`,
  updateStaticContent: `${admin}/updateStaticContent`,
  staticContentList: `${admin}/staticContentList`,
  addStaticContent: `${admin}/addStaticContent`,
  createSubAdmin: `${admin}/createSubAdmin`,
  updateVenue: `${admin}/updateVenue`,
  viewVenueByVenueId: `${admin}/viewVenueByVenueId`,
  newOfferListing: `${admin}/newOfferListing`,

  //dish
  dishlist: `${dish}/adminDishList`,
  updateDish: `${dish}/updateDish`,
  getDish: `${dish}/dishDetails`,
  createDish: `${dish}/createDish`,

  //food
  foodlist: `${food}/adminFoodList`,
  getFood: `${food}/foodDetails`,
  updateFood: `${food}/updateFood`,
  createFood: `${food}/createFood`,


  createFacility: `${facility}/createFacility`,
  adminFacilityList: `${facility}/adminFacilityList`,
  updateFacility: `${facility}/updateFacility`,
  // verifyEmail: `${user}/verifyEmail`,
};

export default ApiConfig;
